<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import rejectionModal from "../../common/modals/rejectionModal";
import approvalModal from "./components/approveRequestModal";
import { barChart } from "./data-overview";

/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Project Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, rejectionModal, approvalModal },
  data() {
    return {
      barChart: barChart,
      // title: "Project Overview",
      uuid: "",
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Maintenance",
          active: true,
        },
      ],

      maintenanceRequestData: {
        id: 0,
        ref: "",
        description: "",
        requestedBy: "",
        requestDate: "",
        requestType: "",
        requestLocationType: "",
        requestLocation: "",
        requestUrgency: "",
        status: "",
        statusPlain: "",
        maintenanceOrderRef: "",
        maintenanceOrder: [],
        modelType: "",
        rejectedBy: "",
        approvedBy: "",
        rejectionReason: "",
        rejectionDate: "",
      },
    };
  },

  mounted() {
    this.uuid = this.$route.params.uid;
    this.fetchSingleMaintenanceRequestData();
  },

  methods: {
    approve() {
      var _this = this;
      Swal.fire({
        title:
          "Êtes-vous sûr d'approuver la requête No: " +
          this.maintenanceRequestData.ref +
          "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Approuver!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete/")
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  _this.fetchSingleMaintenanceRequestData();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    reject() {
      var _this = this;
      Swal.fire({
        title:
          "Êtes-vous sûr de rejeter la requête No: " +
          this.maintenanceRequestData.ref +
          "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "Rejeter!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete/")
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  _this.fetchSingleMaintenanceRequestData();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    fetchSingleMaintenanceRequestData() {
      var _this = this;
      this.$http
        .post("/ops/requests/maintenance/getSingleRequestData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          var maintReq = res.data.original.data.mr;
          switch (status) {
            case 200:
              _this.maintenanceRequestData.id = maintReq.id;
              _this.maintenanceRequestData.ref = maintReq.ref;
              _this.maintenanceRequestData.description = maintReq.description;
              _this.maintenanceRequestData.requestedBy = maintReq.requestedBy;
              _this.maintenanceRequestData.requestDate = maintReq.created_at;
              _this.maintenanceRequestData.requestType = maintReq.type;
              _this.maintenanceRequestData.requestLocationType =
                maintReq.location;
              _this.maintenanceRequestData.requestLocation =
                maintReq.locationRec;
              _this.maintenanceRequestData.requestUrgency = maintReq.urgency;
              _this.maintenanceRequestData.status = maintReq.status;
              _this.maintenanceRequestData.statusPlain = maintReq.statusPlain;
              _this.maintenanceRequestData.maintenanceOrder =
                maintReq.maintenanceOrder;
              _this.maintenanceRequestData.modelType = maintReq.modelType;
              _this.maintenanceRequestData.rejectedBy = maintReq.rejectedBy;
              _this.maintenanceRequestData.rejectionReason =
                maintReq.rejectionReason;
              _this.maintenanceRequestData.rejectionDate =
                maintReq.rejectionDate;
              _this.maintenanceRequestData.approvedBy = maintReq.approvedBy;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="maintenanceRequestData.ref" :items="items" />
    <div class="row" v-if="maintenanceRequestData.statusPlain == 'draft'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div
              class="button-items"
              v-if="maintenanceRequestData.statusPlain == 'draft'"
            >
              <b-button
                variant="primary"
                v-if="maintenanceRequestData.statusPlain == 'draft' && $can('edit_mr')"
              >
                <i class="bx bx-edit font-size-16 align-middle mr-2"></i>
                Modifier
              </b-button>
              <b-button
                variant="success"
                v-b-modal.approvalModal
                v-if="maintenanceRequestData.statusPlain == 'draft' && $can('approve_mr')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2"
                ></i>
                Approuver
              </b-button>

              <b-button
                variant="danger"
                v-b-modal.rejectionModal
                v-if="maintenanceRequestData.statusPlain == 'draft' && $can('reject_mr')"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2"></i>
                Rejeter
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <a
              @click="fetchSingleMaintenanceRequestData()"
              href="javascript:void()"
              ><h1><i class="bx bx-rotate-right" style="float: right;"></i></h1
            ></a>
            <h4 class="card-title mb-4">Détails</h4>

            <p lass="text-muted mb-4">
              {{ maintenanceRequestData.description }}
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Demandeur :</th>
                        <td>{{ maintenanceRequestData.requestedBy }}</td>
                      </tr>
                      <tr>
                        <th>Type de maintenance :</th>
                        <td>{{ maintenanceRequestData.requestType }}</td>
                      </tr>
                      <tr>
                        <th>Lieu de maintenance :</th>
                        <td>
                          {{ maintenanceRequestData.requestLocationType }} /
                          {{ maintenanceRequestData.requestLocation }}
                        </td>
                      </tr>
                      <tr>
                        <th>Urgence :</th>
                        <td>{{ maintenanceRequestData.requestUrgency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Date de demande :</th>
                        <td>{{ maintenanceRequestData.requestDate }}</td>
                      </tr>
                      <tr>
                        <th>Etat :</th>
                        <td>
                          <span v-html="maintenanceRequestData.status"></span>
                        </td>
                      </tr>
                      <tr
                        v-if="maintenanceRequestData.statusPlain == 'rejected'"
                      >
                        <th>Rejeté par :</th>
                        <td>{{ maintenanceRequestData.rejectedBy }}</td>
                      </tr>
                      <tr
                        v-if="maintenanceRequestData.statusPlain == 'rejected'"
                      >
                        <th>Date de rejet:</th>
                        <td>{{ maintenanceRequestData.rejectionDate }}</td>
                      </tr>
                      <tr
                        v-if="maintenanceRequestData.statusPlain == 'rejected'"
                      >
                        <th>Raison de rejet:</th>
                        <td>{{ maintenanceRequestData.rejectionReason }}</td>
                      </tr>

                      <tr
                        v-if="maintenanceRequestData.statusPlain == 'approved'"
                      >
                        <th>Approuvée par :</th>
                        <td>{{ maintenanceRequestData.approvedBy }}</td>
                      </tr>
                      <tr
                        v-if="maintenanceRequestData.statusPlain == 'approved'"
                      >
                        <th>Ordre de maintenance :</th>
                        <td>
                          <router-link
                            :to="{ name: 'maintenanceOrders.view', params: {uid: maintenanceRequestData.maintenanceOrder.uuid}  }"
                            >{{ maintenanceRequestData.maintenanceOrder.ref }}</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <approval-modal
      :modelUid="maintenanceRequestData.id"
      :modelRef="maintenanceRequestData.ref"
    />
    <rejection-modal
      :modelRef="maintenanceRequestData.ref"
      :modelId="maintenanceRequestData.id"
      :modelType="maintenanceRequestData.modelType"
    />
  </Layout>
</template>
